import React from 'react';
import { TextVariant } from '@naf/text';
import { useHistory } from 'react-router';
import { Button } from '@naf/button';
import { useAuth0 } from '@auth0/auth0-react';
import S from './styles';
import { LoginButton } from '../my-naf/Login/Login';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { UserAccessibility } from '../../../../types/articleType';

type Props = {
  userAccessibility: UserAccessibility;
};

const Paywall = ({ userAccessibility: { productArticleLink, paywallOptions } }: Props) => {
  const history = useHistory();
  const internalURL = useDocumentUrlWithNode(productArticleLink?.data);
  const { isAuthenticated } = useAuth0();
  return (
    <S.Wrapper>
      <S.Header variant={TextVariant.Header3}>
        {paywallOptions.paywallTitle || 'Les hele saken som NAF-medlem'}
      </S.Header>
      {paywallOptions.paywallDescription && <S.TextWrapper>{paywallOptions.paywallDescription}</S.TextWrapper>}
      <S.ButtonWrapper>
        <Button
          onClick={(e: any) => {
            e.preventDefault();
            window.dataLayer?.push({
              event: 'cta_click',
              cta_text: 'Bli medlem',
              cta_location: history.location.pathname,
            });
            history.push(internalURL || '/bli-medlem');
          }}
        >
          {paywallOptions.paywallCtaText || 'Bli medlem'}
        </Button>
        {!isAuthenticated && <LoginButton returnUrl={history.location.pathname} variant="outline" />}
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default Paywall;
